import { Injectable, Injector } from '@angular/core';
import { GetSponsorshipDto, SponsorshipServiceProxy, SponsorshipDossierSummaryDto, CreateOrUpdateSponsorshipDto, SponsorshipInvoiceAddressDto, BusinessServiceProxy, SponsorshipDto, CorporateLicenceType } from '@shared/service-proxies/service-proxies';
import { SelectItem } from 'primeng/api';
import { ReplaySubject, tap, map, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { AppConsts } from '../../../shared/AppConsts';
import { AppComponentBase } from '../../../shared/common/app-component-base';
import { CodeService } from '../common/code/code.service';

export interface ISponsorshipDataCache {
    name: string | undefined;
    uid: string | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class SponsorshipDossierService extends AppComponentBase {

    sponsorship: GetSponsorshipDto = null;
    sponsorshipDataSubject = new ReplaySubject<ISponsorshipDataCache>();

    constructor(
        injector: Injector,
        private codeService: CodeService,
        private sponsorshipService: SponsorshipServiceProxy,
        private businessService: BusinessServiceProxy,
    ) {
        super(injector);
    }

    public GetSponsorshipDossiers(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
        return this.sponsorshipService.getDossiers(filter, sorting, maxResultCount, skipCount);
    }

    public LoadSponsorship(id: number): Observable<GetSponsorshipDto> {
        return this.sponsorshipService.getSponsorship(id).pipe(tap(sponsorship => {
            this.sponsorship = sponsorship;
        }));
    }

    public GetId(): number {
        return this.sponsorship?.id;
    }

    public GetUid(): string {
        return this.sponsorship.uid;
    }

    public GetSponsorship(): Observable<GetSponsorshipDto> {
        return of(this.sponsorship);
    }

    createEmploymentAddressDropdownList(includeSponsorship: boolean = false): Observable<SelectItem[]> {

        return this.businessService.getBusinessesForSponsorship(this.sponsorship.id).pipe(map(businesses => {
            var dropdownList: SelectItem[] = [];

            if (!this.sponsorship.isPublicPharmacy) {
                dropdownList.push({ value: AppConsts.employmentAddressType.mobile, label: this.l('MobileAddress') });
            }

            if (includeSponsorship) {
                dropdownList.push({ value: AppConsts.employmentAddressType.sponsorship, label: this.l('SponsorshipAddress') });
            }

            businesses.forEach(business => {
                dropdownList.push({ value: business.id, label: this.l('Business') + ': ' + this.l(business.name) });
            })

            return dropdownList;
        }));
    }

    public GetInvoiceAddress() {
        return this.sponsorshipService.getInvoiceAddress(this.sponsorship.id);
    }

    public UpdateInvoiceAddress(input: SponsorshipInvoiceAddressDto) {
        return this.sponsorshipService.updateInvoiceAddress(this.sponsorship.id, input);
    }

    public GetSponsorshipDataSubject(): Observable<ISponsorshipDataCache> {
        return this.sponsorshipDataSubject;
    }

    public GetSponsorshipDossierSummary(): Observable<SponsorshipDossierSummaryDto> {
        return this.sponsorshipService.getSponsorshipSummary(this.sponsorship.id).pipe(tap((x: SponsorshipDossierSummaryDto) => {
            this.sponsorshipDataSubject.next(x);
        }));
    }

    public UpdateSponsorshipCoreData(input: CreateOrUpdateSponsorshipDto) {
        return this.sponsorshipService.update(input)
            .pipe(tap((output: GetSponsorshipDto) => {
                this.sponsorship = output;
                this.sponsorshipDataSubject.next(input);
            }));
    }

    getCorporateLicenceTypes(allOnTop: boolean) {
        return this.codeService.getEnumForDropdown('CorporateLicenceType', CorporateLicenceType, allOnTop);
    }
}
