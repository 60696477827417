export class AppConsts {
    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static remoteServiceBaseUrlFormat: string;
    static appBaseUrl: string;
    static applicantBaseUrl = '/app';
    static applicantStartUrl = '/app/my-sirona';
    static authorityBaseUrl = '/app/authority';
    static personDossierBaseUrl = '/app/authority/person-dossier';
    static authorityStartUrl = '/app/authority/applications';
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static appBaseUrlFormat: string;
    static recaptchaSiteKey: string;
    static subscriptionExpireNootifyDayCount: number;

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin',
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'Sirona',
    };

    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token',
        autoLoginInfo: 'auto_login_info',
    };

    static readonly grid = {
        defaultPageSize: 10,
        maxPageSize: 1000, // see server side AppConsts.MaxPageSize
    };

    static readonly maintenance = class setting {
        static readonly enableMaintenanceBanner = 'App.Maintenance.EnableMaintenanceBanner';

        static readonly disableLiaCreateUntil = 'App.Maintenance.DisableLiaCreateUntil';
        static readonly disableLiaSubmissionUntil = 'App.Maintenance.DisableLiaSubmissionUntil';
        static readonly disableLiaServiceUntil = 'App.Maintenance.DisableLiaServiceUntil';
        static readonly disableLiaForAuthority = 'App.Maintenance.DisableLiaForAuthority';

        static readonly disableClaCreateUntil = 'App.Maintenance.DisableClaCreateUntil';
        static readonly disableClaSubmissionUntil = 'App.Maintenance.DisableClaSubmissionUntil';
        static readonly disableClaServiceUntil = 'App.Maintenance.DisableClaServiceUntil';
        static readonly disableClaForAuthority = 'App.Maintenance.DisableClaForAuthority';
    };

    static readonly sirona = class {
        static readonly setting = class {
            static readonly disableLanguageOfCorrespondence = 'App.Sirona.Setting.DisableLanguageOfCorrespondence';
            static readonly ignoreLanguageRequirementIfLicenceInAnotherCanton =
                'App.Sirona.Setting.IgnoreLanguageRequirementIfLicenceInAnotherCanton';
            static readonly ignoreEmploymentAddressPostCodeHasToBeInCanton =
                'App.Sirona.Setting.IgnoreEmploymentAddressPostCodeHasToBeInCanton';
            static readonly disableRejectLicenceAction = 'App.Sirona.Setting.DisableRejectLicenceAction';
            static readonly disableApplicationReactivation = 'App.Sirona.Setting.DisableApplicationReactivation';
            static readonly maxFileLengthForDocuments = 'App.Sirona.Setting.MaxFileLengthForDocuments';
            static readonly allowedFileExtensionsForDocuments = 'App.Sirona.Setting.AllowedFileExtensionsForDocuments';
            static readonly enableWindowsAuthentication = 'App.Sirona.Setting.EnableWindowsAuthentication';
        };
    };

    static readonly icons = class {
        static readonly wizard = class {
            static readonly ehc = class {
                static readonly person: string = 'fa fa-user fa-fw';
                static readonly postgraduateTitle: string = 'fa fa-stethoscope fa-fw';
                static readonly staff: string = 'fa fa-solid fa-users';
                static readonly qualitySupportSystem: string = 'fa fa-solid fa-book-medical';
                static readonly selfEmployment: string = 'fa fa-user fa-fw';
                static readonly register: string = 'fa fa-briefcase-medical fa-fw';
                static readonly medicalField: string = 'fa fa-stethoscope fa-fw';
                static readonly languageSkill: string = 'fa fa-comment-dots fa-fw';
                static readonly practice: string = 'fa fa-microscope fa-fw';
                static readonly electronicPatientRecord: string = 'fa fa-laptop-medical fa-fw';
                static readonly qualityMeasurement: string = 'fa fa-list-check fa-fw';
                static readonly contact: string = 'fa fa-id-card fa-fw';
                static readonly employment: string = 'fa fa-user-md fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
            };
            static readonly lia = class {
                static readonly person: string = 'fa fa-user fa-fw';
                static readonly contact: string = 'fa fa-id-card fa-fw';
                static readonly restriction: string = 'fa fa-thumbs-up fa-fw';
                static readonly employment: string = 'fa fa-user-md fa-fw';
                static readonly previousEmployment: string = 'fa fa-clock-rotate-left fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
                static readonly document: string = 'fa fa-file-upload fa-fw';
                static readonly languageSkill: string = 'fa fa-comment-dots fa-fw';
                static readonly diploma: string = 'fa fa-scroll fa-fw';
                static readonly academicTitle: string = 'fa fa-graduation-cap fa-fw';
                static readonly postgraduateTitlePrivateLaw: string = 'fa fa-chalkboard fa-fw';
                static readonly postgraduateTitle: string = 'fa fa-chalkboard-user fa-fw';
                static readonly guarantee: string = 'fa fa-notes-medical fa-fw';
                static readonly licenceInAnotherCanton: string = 'fa fa-stamp fa-fw';
                static readonly professionalResponsibility: string = 'fa fa-light fas fa-sync';
            };
            static readonly slap = class {
                static readonly person: string = 'fa fa-user fa-fw';
                static readonly business: string = 'fa fa-location-dot fa-fw';
                static readonly practice: string = 'fa fa-stamp fa-fw';
                static readonly rangeOfProducts: string = 'fa fa-light fa-pills';
                static readonly productPreparation: string = 'fa fa-microscope fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
            };
            static readonly ala = class {
                static readonly applicantPerson: string = 'fa fa-user fa-fw';
                static readonly applicantSponsorship: string = 'fa fa-user fa-fw';
                static readonly person: string = 'fa fa-user fa-fw';
                static readonly medicalFieldDirector: string = 'fa fa-user-doctor fa-fw';
                static readonly contact: string = 'fa fa-id-card fa-fw';
                static readonly diploma: string = 'fa fa-scroll fa-fw';
                static readonly business: string = 'fa fa-location-dot fa-fw';
                static readonly employment: string = 'fa fa-user-md fa-fw';
                static readonly previousEmployment: string = 'fa fa-clock-rotate-left fa-fw';
                static readonly currentAssistant: string = 'fa fas fa-users';
                static readonly academicTitle: string = 'fa fa-graduation-cap fa-fw';
                static readonly languageSkill: string = 'fa fa-comment-dots fa-fw';
                static readonly postgraduateTitle: string = 'fa fa-chalkboard-user fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
                static readonly trustworthiness: string = 'fa fas fa-handshake';
            };
            static readonly spl = class {
                static readonly person: string = 'fa fa-user fa-fw';
            };
            static readonly mut = class {
                static readonly applicantPerson: string = 'fa fa-user fa-fw';
                static readonly applicantSponsorship: string = 'fa fa-user fa-fw';
                static readonly applicantContact: string = 'fa fa-user fa-fw';
                static readonly applicantBusiness: string = 'fa fa-user fa-fw';
                static readonly applicantAdditionalComments: string = 'fa fa-user fa-fw';
                static readonly additionalAcademicTitle: string = 'fa fa-user fa-fw';
                static readonly postgraduateTitlePrivateLaw: string = 'fa fa-user fa-fw';
                static readonly person: string = 'fa fa-user fa-fw';
                static readonly business: string = 'fa fa-location-dot fa-fw';
                static readonly practice: string = 'fa fa-stamp fa-fw';
                static readonly rangeOfServices: string = 'fa fa-light fa-pills';
            };
            static readonly cla = class {
                static readonly sponsorship: string = 'fa fa-hospital fa-fw';
                static readonly sponsorshipDirectors: string = 'fa fa-users fa-fw';
                static readonly invoiceAddress: string = 'fa fa-dollar-sign fa-fw';
                static readonly businesses: string = 'fa fa-location-dot fa-fw';
                static readonly businessDirectors: string = 'fa fa-user-doctor fa-fw';
                static readonly medicalFieldDirector: string = 'fa fa-user-doctor fa-fw';
                static readonly assistantPhysicians: string = 'fa fa-user-graduate fa-fw';
                static readonly medicalFieldPerson: string = 'fa fa-user-doctor fa-fw';
                static readonly businessPhysicianManagement: string = 'fa fa-user fa-fw';
                static readonly infrastructure: string = 'fa fa-hospital fa-fw';
                static readonly rangeOfServices: string = 'fa fa-book-medical fa-fw';
                static readonly rangeOfProducts: string = 'fa fa-pills fa-fw';
                static readonly productPreparation: string = 'fa fa-microscope fa-fw';
                static readonly localDrugSales: string = 'fa fa-capsules fa-fw';
                static readonly drugProduction: string = 'fa fa-prescription-bottle-medical fa-fw';
                static readonly drugProductionThirdParty: string = 'fa fa-prescription-bottle-medical fa-fw';
                static readonly onlineDrugSales: string = 'fa fa-truck-fast fa-fw';
                static readonly medicalFieldDirectorRepresentative: string = 'fa fa-user fa-fw';
                static readonly serviceArea: string = 'fa fa-user fa-fw';
                static readonly treatment: string = 'fa fa-hand-holding-medical fa-fw';
                static readonly nursingBeds: string = 'fa fa-bed-pulse fa-fw';
                static readonly floors: string = 'fa fa-hospital fa-fw';
                static readonly wheelchairAccessibility: string = 'fa fa-wheelchair fa-fw';
                static readonly sanitaryFacilities: string = 'fa fa-sink fa-fw';
                static readonly drugChambers: string = 'fa fa-kit-medical fa-fw';
                static readonly security: string = 'fa fa-shield-halved fa-fw';
                static readonly aidingResources: string = 'fa fa-kit-medical fa-fw';
                static readonly personel: string = 'fa fa-users fa-fw';
                static readonly quality: string = 'fa fa-list-check fa-fw';
                static readonly release: string = 'fa fa-user fa-fw';
                static readonly released: string = 'fa fa-user fa-fw';
                static readonly finishReview: string = 'fa fa-user fa-fw';
            };
        };
    };

    static readonly maxStringLength = class {
        static readonly short: number = 10;
        static readonly middle: number = 100;
        static readonly intermediary: number = 250;
        static readonly long: number = 1000;
        static readonly gln: number = 13;
        static readonly ahv: number = 16;
        static readonly zsr: number = 10;
        static readonly uid: number = 20;
    };

    static readonly employmentAddressType = class {
        static readonly mobile: number = -1;
        static readonly sponsorship: number = -2;
    };

    static readonly codes = class {
        static readonly successfulLicenceApplication = class {
            static readonly canton = 'SuccessfulLicenceApplication-Canton';
            static readonly country = 'SuccessfulLicenceApplication-Country';
            static readonly cantonAndCountry = 'SuccessfulLicenceApplication-CantonAndCountry';
            static readonly none = 'SuccessfulLicenceApplication-None';
        };
        static readonly address = class {
            static readonly private: string = 'Address-Private';
            static readonly business: string = 'Address-Business';
            static readonly invoice: string = 'Address-Invoice';
        };
        static readonly canton = class {
            static readonly be: string = 'Canton-Bern';
            static readonly zh: string = 'Canton-Zuerich';
            static readonly original: string = 'Canton-Urkantone';
            static readonly unknown: string = 'Canton-Unbekannt';
        };
        static readonly employmentRole = class {
            static readonly sponsorshipResponsible: string = 'EmploymentRole-SponsorshipResponsible';
            static readonly affiliatedPhysician: string = 'EmploymentRole-AffiliatedPhysician';
            static readonly medicalFieldResponsible: string = 'EmploymentRole-MedicalFieldResponsible';
            static readonly practiceRepresentative: string = 'EmploymentRole-PracticeRepresentative';
            static readonly selfDependentEmployee: string = 'EmploymentRole-SelfDependentEmployee';
            static readonly businessResponsible: string = 'EmploymentRole-BusinessResponsible';
            static readonly assistantPhysician: string = 'EmploymentRole-AssistantPhysician';
            static readonly ninetyDaysServiceProvider: string = 'EmploymentRole-NinetyDaysServiceProvider';
        };
        static readonly employmentType = class {
            static readonly employed: string = 'EmploymentType-Employed';
            static readonly selfEmployed: string = 'EmploymentType-SelfEmployed';
        };
        static readonly employmentContractType = class {
            static readonly permanent: string = 'EmploymentContractType-Permanent';
            static readonly fixedTerm: string = 'EmploymentContractType-FixedTerm';
        };
        static readonly employmentLocationType = class {
            static readonly unknown: string = 'EmploymentLocationType-Unknown';
        };

        static readonly businessLegalForm = class {
            static readonly other: string = 'BusinessLegalForm-Other';
            static readonly unknown: string = 'BusinessLegalForm-Unknown';
            static readonly einzelfirma: string = 'BusinessLegalForm-Einzelfirma';
            static readonly einfacheGesellschaft: string = 'BusinessLegalForm-EinfacheGesellschaft';
            static readonly kollektivgesellschaft: string = 'BusinessLegalForm-Kollektivgesellschaft';
            static readonly kommanditgesellschaft: string = 'BusinessLegalForm-Kommanditgesellschaft';
        };
        static readonly employmentStatus = class {
            static readonly initial: string = 'EmploymentStatus-Initial';
            static readonly definite: string = 'EmploymentStatus-Definite';
            static readonly discarded: string = 'EmploymentStatus-Discarded';
        };
        static readonly businessStatus = class {
            static readonly initial: string = 'BusinessStatus-Initial';
            static readonly fromApplication: string = 'BusinessStatus-FromApplication';
            static readonly definite: string = 'BusinessStatus-Definite';
            static readonly discarded: string = 'BusinessStatus-Discarded';
        };

        static readonly country = class {
            static readonly ch: string = 'Country-Schweiz';
            static readonly de: string = 'Country-Deutschland';
            static readonly fr: string = 'Country-Frankreich';
            static readonly nl: string = 'Country-Niederlande';
            static readonly at: string = 'Country-Oesterreich';
            static readonly it: string = 'Country-Italien';
            static readonly gr: string = 'Country-Griechenland';
            static readonly be: string = 'Country-Belgien';
            static readonly pl: string = 'Country-Polen';
            static readonly gb: string = 'Country-Grossbritannien und Nordirland';
            static readonly hu: string = 'Country-Ungarn';
            static readonly ro: string = 'Country-Rumaenien';
            static readonly pt: string = 'Country-Portugal';
            static readonly es: string = 'Country-Spanien';
            static readonly cz: string = 'Country-Tschechische Republik';
            static readonly se: string = 'Country-Schweden';
            static readonly sk: string = 'Country-Slowakische Republik (Slowakei)';
            static readonly bg: string = 'Country-Bulgarien';
            static readonly fi: string = 'Country-Finnland';
            static readonly lt: string = 'Country-Litauen';
            static readonly hr: string = 'Country-Kroatien';
            static readonly lu: string = 'Country-Luxemburg';
            static readonly li: string = 'Country-Fuerstentum Liechtenstein';
            static readonly yu: string = 'Country-Jugoslawien';
        };
        static readonly applicationReleaseAction = class {
            static readonly ehcAndLia: string = 'ApplicationReleaseAction-EhcAndLia';
            static readonly liaOnly: string = 'ApplicationReleaseAction-LiaOnly';
            static readonly withdrawAll: string = 'ApplicationReleaseAction-WithdrawAll';
        };
        static readonly identificationStatus = class {
            static readonly none: string = 'IdentificationStatus-None';
            static readonly submitted: string = 'IdentificationStatus-Submitted';
            static readonly evaluation: string = 'IdentificationStatus-Evaluation';
            static readonly granted: string = 'IdentificationStatus-Granted';
            static readonly rejected: string = 'IdentificationStatus-Rejected';
        };
        static readonly identificationType = class {
            static readonly documentUpload: string = 'IdentificationType-IdentificationCardDocumentUpload';
            static readonly applicationDocument: string = 'IdentificationType-ApplicationDocument';
        };

        static readonly invoiceAddressType = class {
            static readonly invoice: string = 'InvoiceAddressType-Invoice';
            static readonly private: string = 'InvoiceAddressType-Private';
            static readonly employment: string = 'InvoiceAddressType-Employment';
            static readonly alternatePerson: string = 'InvoiceAddressType-AlternatePerson';
            static readonly alternateSponsorship: string = 'InvoiceAddressType-AlternateSponsorship';
            static readonly alternateEmployment: string = 'InvoiceAddressType-AlternateEmployment';
        };

        static readonly invoiceContext = class {
            static readonly professionalLicence: string = 'InvoiceContext-ProfessionalLicence';
            static readonly corporateLicence: string = 'InvoiceContext-CorporateLicence';
        };

        static readonly invoiceDistributionMethod = class {
            static readonly pdf: string = 'InvoiceDistributionMethod-Pdf';
        };

        static readonly invoiceStatus = class {
            static readonly initial: string = 'InvoiceStatus-Initial';
            static readonly creationError: string = 'InvoiceStatus-CreationError';
            static readonly businessPartnerCreationError: string = 'InvoiceStatus-BusinessPartnerCreationError';
            static readonly transferred: string = 'InvoiceStatus-Transferred';
            static readonly closed: string = 'InvoiceStatus-Closed';
        };

        static readonly changeRequestStatus = class {
            static readonly submitted: string = 'ChangeRequestStatus-Submitted';
            static readonly underEvaluation: string = 'ChangeRequestStatus-UnderEvaluation';
            static readonly autoGranted: string = 'ChangeRequestStatus-AutoGranted';
            static readonly granted: string = 'ChangeRequestStatus-Granted';
            static readonly rejected: string = 'ChangeRequestStatus-Rejected';
            static readonly completed: string = 'ChangeRequestStatus-Completed';
        };
        static readonly pendenceStatus = class {
            static readonly oepn: string = 'PendenceStatus-Open';
            static readonly inProcess: string = 'PendenceStatus-InProcess';
            static readonly toVerify: string = 'PendenceStatus-ToVerify';
            static readonly completed: string = 'PendenceStatus-Completed';
        };
        static readonly pendenceField = class {
            static readonly general: string = 'PendenceField-General';
            static readonly mutation: string = 'PendenceField-Mutation';
        };
        static readonly pendenceType = class {
            static readonly external: string = 'PendenceType-External';
            static readonly internal: string = 'PendenceType-Internal';
        };
        static readonly language = class {
            static readonly german: string = 'Language-German';
            static readonly french: string = 'Language-French';
        };
        static readonly languageSkill = class {
            static readonly german: string = 'LanguageSkill-German';
            static readonly french: string = 'LanguageSkill-French';
            static readonly other: string = 'LanguageSkill-Other';
        };
        static readonly professionalLicenceStatus = class {
            static readonly granted: string = 'ProfessionalLicenceStatus-Granted';
            static readonly banned: string = 'ProfessionalLicenceStatus-Banned';
            static readonly refused: string = 'ProfessionalLicenceStatus-Refused';
            static readonly restriced: string = 'ProfessionalLicenceStatus-Restricted';
            static readonly requested: string = 'ProfessionalLicenceStatus-Requested';
            static readonly inactive: string = 'ProfessionalLicenceStatus-Inactive';
            static readonly revoked: string = 'ProfessionalLicenceStatus-Revoked';
            static readonly noLicence: string = 'ProfessionalLicenceStatus-NoLicence';
        };
        static readonly expenseHealthCarePersonalLicenceStatus = class {
            static readonly granted: string = 'ProfessionalLicenceStatus-Granted';
            static readonly banned: string = 'ProfessionalLicenceStatus-Banned';
            static readonly refused: string = 'ProfessionalLicenceStatus-Refused';
            static readonly restriced: string = 'ProfessionalLicenceStatus-Restricted';
            static readonly requested: string = 'ProfessionalLicenceStatus-Requested';
            static readonly inactive: string = 'ProfessionalLicenceStatus-Inactive';
            static readonly revoked: string = 'ProfessionalLicenceStatus-Revoked';
            static readonly noLicence: string = 'ProfessionalLicenceStatus-NoLicence';
        };
        static readonly postgraduateTitle = class {
            static readonly other: string = 'PostgraduateTitle-Other';
        };
        static readonly previousEmploymentType = class {
            static readonly selfEmployed: string = 'PreviousEmploymentType-ProfessionalPracticeUnderOwnProfessionalResponsibility';
            static readonly employed: string = 'PreviousEmploymentType-ProfessionalPracticeUnderProfessionalResponsibilityAndEmployed';
        };
        static readonly profession = class {
            // (medreg)
            static readonly physician: string = 'Profession-Physician';
            static readonly dentist: string = 'Profession-Dentist';
            static readonly pharmacist: string = 'Profession-Pharmacist';
            static readonly chiropractor: string = 'Profession-Chiropractor';

            // (nareg)
            static readonly nursing: string = 'Profession-Nursing';
            static readonly physiotherapist: string = 'Profession-Physiotherapist';
            static readonly occupationalTherapist: string = 'Profession-OccupationalTherapist';
            static readonly midwife: string = 'Profession-Midwife';
            static readonly nutritionist: string = 'Profession-Nutritionist';
            static readonly optometrist: string = 'Profession-Optometrist';
            static readonly osteopath: string = 'Profession-Osteopath';
            static readonly optician: string = 'Profession-Optician';
            static readonly podiatrist: string = 'Profession-Podiatrist';
            static readonly druggist: string = 'Profession-DruggistHF';
            static readonly dentalHygienist: string = 'Profession-DentalHygienist';
            static readonly paramedic: string = 'Profession-Paramedic';
            static readonly therapistTCM: string = 'Profession-TherapistTCM';
            static readonly alternativePractitionerTEN: string = 'Profession-AlternativePractitionerTEN';
            static readonly homoeopath: string = 'Profession-Homeopath';
            static readonly acupuncturist: string = 'Profession-Acupuncturist';

            // (psyreg)
            static readonly psychotherapist: string = 'Profession-Psychotherapist';

            static readonly andere: string = 'Profession-Andere';
        };
        static readonly yesNo = class {
            static readonly yes: string = 'YesNo-Yes';
            static readonly no: string = 'YesNo-No';
        };
        static readonly electronicPatientRecord = class {
            static readonly baseCommunityEHealthAargau: string = 'ElectronicPatientRecord-BaseCommunityEhealthAargau';
            static readonly baseCommunityESANITA: string = 'ElectronicPatientRecord-BaseCommunityESANITA';
            static readonly cara: string = 'ElectronicPatientRecord-CARA';
            static readonly referenceCommunityNeuchatel: string = 'ElectronicPatientRecord-ReferenceCommunityNeuchatel';
            static readonly associationEHealthTicino: string = 'ElectronicPatientRecord-AssociationEHealthTicino';
            static readonly xadStammgemeinschaft: string = 'ElectronicPatientRecord-XADBaseCommunity';
            static readonly abilisAG: string = 'ElectronicPatientRecord-AbilisAG';
            static readonly adSwiss: string = 'ElectronicPatientRecord-ADSwiss';
            static readonly other: string = 'ElectronicPatientRecord-Other';
        };
        static readonly documentType = class {
            static readonly other: string = 'Document-Other';
            static readonly centralCriminalRecordExtract: string = 'Document-CentralCriminalRecordExtract';
            static readonly foreignCriminalRecordExtract = 'Document-ForeignCriminalRecordExtract';
            static readonly curriculumVitae: string = 'Document-CurriculumVitae';
            static readonly postgraduatePractiseCertificate: string = 'Document-PostgraduatePractiseCertificate';
            static readonly medicalCertificate: string = 'Document-MedicalCertificate';
            static readonly professionalLicence: string = 'Document-ProfessionalLicence';
            static readonly professionalLicenceApplication: string = 'Document-ProfessionalLicenceApplication';
            static readonly professionalLicenceCoverLetter: string = 'Document-ProfessionalLicenceCoverLetter';
            static readonly statute: string = 'Document-Statute';
            static readonly timeSchedule: string = 'Document-TimeSchedule';
            static readonly coverLetter: string = 'Document-CoverLetter';
            static readonly operationLicence: string = 'Document-OperationLicence';
            static readonly proofOfLanguageProficiency: string = 'Document-ProofOfLanguageProficiency';
            static readonly diplomaOfAtLeastThreeYearsOfProfessionalTraining: string =
                'Document-DiplomaOfAtLeastThreeYearsOfProfessionalTraining';
            static readonly evidencOfRequiredHours: string = 'Document-EvidencOfRequiredHours';
            static readonly confirmatioOfActivityUnderProfessionalSupervision: string =
                'Document-ConfirmatioOfActivityUnderProfessionalSupervision';
            static readonly proofOfPractice: string = 'Document-ProofOfPractice';
        };

        static readonly dmsDocTypes = class {
            static readonly signatureCard: string = 'DmsDocType-SignatureCard';
            static readonly idCard: string = 'DmsDocType-IdCard';
            static readonly decree: string = 'DmsDocType-Decree';
        }

        static readonly sponsorshipType = class {
            static readonly hospital: string = 'SponsorshipType-Hospital';
            static readonly outpatientPhysicianClinic: string = 'SponsorshipType-OutpatientPhysicianClinic';
            static readonly outpatientPhysicianPolyclinic: string = 'SponsorshipType-OutpatientPhysicianPolyclinic';
            static readonly outpatientChiropractorClinic: string = 'SponsorshipType-OutpatientChiropractorClinic';
            static readonly outpatientDentalClinic: string = 'SponsorshipType-OutpatientDentalClinic';
            static readonly outpatientDentalPolyclinic: string = 'SponsorshipType-OutpatientDentalPolyclinic';
            static readonly publicPharmacy: string = 'SponsorshipType-PublicPharmacy';
        };

        static readonly evaluationStatus = class {
            static readonly ok: string = 'EvaluationStatus-Ok';
            static readonly notOk: string = 'EvaluationStatus-NotOk';
            static readonly internalClarificationRequired: string = 'EvaluationStatus-InternalClarificationRequired';
        };

        static readonly psyreg = class {
            static readonly postgraduateTitle = class {
                static readonly type = class {
                    static readonly federal: string = 'PostgraduateTitle-PsychologyFederal';
                    static readonly foreignAndRecognized: string = 'PostgraduateTitle-PsychologyForeignAndRecognized';
                    static readonly foreignAndVerified: string = 'PostgraduateTitle-PsychologyForeignAndVerified';
                };
            };
        };

        static readonly diploma = class {
            static readonly psyreg = class {
                static readonly type = class {
                    static readonly federalPsyReg: string = 'DiplomaType-FederalPsyReg';
                    static readonly foreignAndRecognized: string = 'DiplomaType-ForeignAndRecognized';
                    static readonly foreignAndVerified: string = 'DiplomaType-ForeignAndVerified';
                };
            };

            static readonly medreg = class {
                static readonly type = class {
                    static readonly federal: string = 'DiplomaType-Federal';
                    static readonly mebekoRecognized: string = 'DiplomaType-MebekoRecognized';
                    static readonly mebekoEquivalent: string = 'DiplomaType-MebekoEquivalent';
                    static readonly other: string = 'DiplomaType-Other';
                    static readonly undefined: string = 'DiplomaType-Undefined';
                    static readonly verifiedAccordingToBGMD: string = 'DiplomaType-VerifiedAccordingToBGMD';
                    static readonly foreignCheckedAndNotRecognized: string =
                        'DiplomaType-ForeignCheckedAndNotRecognized';
                };
            };
        };

        static readonly external = class {
            static readonly medreg = class {
                static readonly diploma = class {
                    static readonly typ = class {
                        static readonly ueberprueftNichtAnnerkant: number = 15006;
                    };
                };
                static readonly weiterbildungstitel = class {
                    static readonly typ = class {
                        static readonly eidg: number = 16000;
                        static readonly annerkanterMebeko: number = 16001;
                        static readonly gleichwertigerMebeko: number = 16002;
                        static readonly andere: number = 16003;
                        static readonly unbekannt: number = 16004;
                        static readonly nachgeprueftBGMD: number = 16005;
                    };
                };
            };

            static readonly nareg = class {
                static readonly licence = class {
                    static readonly type = class {
                        static readonly dla90: number = 11002;
                    };
                };
            };
        };

        static readonly applicationMode = class {
            static readonly readOnly: string = 'readOnly';
            static readonly cancel: string = 'cancel';
            static readonly evaluation: string = 'evaluation';
        };
    };

    static readonly MinimumUpgradePaymentAmount = 1;

    static readonly LuxonDateFormat = 'd.L.yyyy';
    static readonly PrimeNgDateFormat = 'dd.mm.yy';
    /// <summary>
    /// Gets current version of the application.
    /// It's also shown in the web page.
    /// </summary>
    /// <summary>
    /// Redirects users to host URL when using subdomain as tenancy name for not existing tenants
    /// </summary>
    static readonly PreventNotExistingTenantSubdomains = false;
    static readonly WebAppGuiVersion = '10.3.0';

    static readonly features = class {
        static readonly personDossier: string = 'App.PersonDossier';
        static readonly sponsorshipDossier: string = 'App.SponsorshipDossier';
        static readonly dashboard: string = 'App.Dashboard';
    };

    static readonly dmsDiscriminators = class {
        static readonly person: string = 'Person';
        static readonly personLicence: string = 'PersonLicence';
        static readonly corporateLicence: string = 'CorporateLicence';
        static readonly sapInvoice: string = 'SapInvoice';
        static readonly application: string = 'Application';
        static readonly business: string = 'Business';
        static readonly sponsorship: string = 'Sponsorship';
        static readonly fileDossier: string = 'FileDossier';
        static readonly fileDossierDocument: string = 'FileDossierDocument';
        static readonly sensitiveData: string = 'SensitiveData';
        static readonly sensitiveDataRecord: string = 'SensitiveDataRecord';
        static readonly criminalPenalty: string = 'CriminalPenalty';
        static readonly criminalPenaltyRecord: string = 'CriminalPenaltyRecord';
    };

    static readonly pendenceDiscriminators = class {
        static readonly person: string = 'PendenceLinkPerson';
        static readonly sponsorship: string = 'PendenceLinkSponsorship';
        static readonly application: string = 'PendenceLinkApplication';
        static readonly licence: string = 'PendenceLinkLicence';
    };

    static readonly dmsTemplateDiscriminators = class {
        static readonly person: string = 'Person';
        static readonly sponsorship: string = 'Sponsorship';
        static readonly qrcode: string = 'QrCode';
        static readonly personCoverSheet: string = 'PersonCoverSheet';
        static readonly sponsorshipCoverSheet: string = 'SponsorshipCoverSheet';
        static readonly fileDossier: string = 'FileDossier';
    };
}
