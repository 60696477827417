import { Injectable, Injector } from '@angular/core';
import { ApplicationService } from '@app/shared/application/application.service';
import { CodeService } from '@app/shared/common/code/code.service';
import { ProfessionConfigurationService } from '@app/shared/services/profession-configuration.service';
import { AppConsts } from '@shared/AppConsts';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
    ApplicationServiceProxy,
    ApplicationUploadFilesInput,
    CreateMutInput,
    CreateMutOutput,
    ExternalSystemEnum,
    GeneralFileDto,
    GeneralFileInfoDto,
    GetMutOutput,
    LiaTypeServiceProxy,
    ProfessionRegisterAffiliationServiceProxy,
    MutDto,
    MutPersonDto,
    MutPersonServiceProxy,
    MutServiceProxy,
    MutStatus,
    MutApplicantContactDto,
    MutApplicantContactServiceProxy,
    MutApplicantAdditionalCommentsServiceProxy,
    MutApplicantAdditionalCommentsDto,
    MutApplicantPersonServiceProxy,
    MutApplicantBusinessServiceProxy,
    MutApplicantBusinessDto,
    MutApplicantPersonDto,
    MutApplicantSponsorshipServiceProxy,
    MutApplicantSponsorshipDto,
    MutAdditionalAcademicTitleServiceProxy,
    MutAdditionalAcademicTitleDto,
    MutPostgraduateTitlePrivateLawServiceProxy,
    MutPostgraduateTitlePrivateLawDto,
    MutAddressServiceProxy,
    MutAddressDto,
    MutWorkPlaceServiceProxy,
    MutWorkPlaceDto,
    CreateUpdateMutApplicantBusinessInput,
    MutEmploymentServiceProxy,
    MutEmploymentDto,
    MutEmploymentTerminationServiceProxy,
    MutEmploymentTerminationDto,
    MutSponsorshipDto,
    MutSponsorshipServiceProxy,
    MutOtherDto,
    MutOtherServiceProxy,
    MutTerminationOfClaServiceProxy,
    MutTransferOfClaServiceProxy,
    MutBusinessServiceProxy,
    MutRangeOfServicesServiceProxy,
    MutDrugProductionServiceProxy,
    MutSponsorshipDirectorServiceProxy,
    MutMedicalFieldDirectorServiceProxy,
    MutBusinessDirectorsServiceProxy,
    MutBusinessTerminationServiceProxy,
    MutBusinessChangeServiceProxy,
    MutTerminationOfClaDto,
    MutTransferOfClaDto,
    MutBusinessDto,
    MutRangeOfServicesDto,
    MutDrugProductionDto,
    MutDocumentServiceProxy,
    GetMutDocumentsInput,
    MutDocumentsOutput,
    MutMedicalFieldDirectorDto,
    MutBusinessDirectorDto,
    MutBusinessTerminationDto,
    MutBusinessChangeDto,
    GetMutSponsorshipDirectorsOutput,
    CreateUpdateMutSponsorshipDirectorsInput,
    GetMutBusinessDirectorsOutput,
    CreateUpdateMutBusinessDirectorsInput,
    GetMutFileInput,
    GeneralDocumentGroupDto,
    MutationApplicationServiceProxy,
    CreatePendenceDto,
    PendenceLinkDto,
    ApplicationType,
    GetMutSubItemDocumentsInput,
} from './../../../shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';

@Injectable()
export class MutService extends ApplicationService {

    mut: MutDto;
    mutResultDocuments: GeneralFileInfoDto[];
    healthRegisterSystem: ExternalSystemEnum;
    mutApplicationTypes = [ApplicationType.Mut] as const;

    constructor(
        injector: Injector,
        private appService: ApplicationServiceProxy,
        codeService: CodeService,
        private liaService: LiaTypeServiceProxy,
        private mutService: MutServiceProxy,
        private personService: MutPersonServiceProxy,
        private applicantContactService: MutApplicantContactServiceProxy,
        private applicantPersonService: MutApplicantPersonServiceProxy,
        private applicantBusinessService: MutApplicantBusinessServiceProxy,
        private applicantSponsorshipService: MutApplicantSponsorshipServiceProxy,
        private applicantAdditionalCommentsService: MutApplicantAdditionalCommentsServiceProxy,
        private additionalAcademticTitleService: MutAdditionalAcademicTitleServiceProxy,
        private postgraduateTitlePrivateLawService: MutPostgraduateTitlePrivateLawServiceProxy,
        private addressService: MutAddressServiceProxy,
        private workPlaceService: MutWorkPlaceServiceProxy,
        private employmentService: MutEmploymentServiceProxy,
        private employmentTerminationService: MutEmploymentTerminationServiceProxy,
        private otherService: MutOtherServiceProxy,
        private sponsorshipService: MutSponsorshipServiceProxy,
        private terminationOfClaService: MutTerminationOfClaServiceProxy,
        private transferOfClaService: MutTransferOfClaServiceProxy,
        private businessService: MutBusinessServiceProxy,
        private businessChangeService: MutBusinessChangeServiceProxy,
        private rangeOfServicesService: MutRangeOfServicesServiceProxy,
        private drugProductionService: MutDrugProductionServiceProxy,
        private sponsorshipDirectorService: MutSponsorshipDirectorServiceProxy,
        private medicalFieldDirectorService: MutMedicalFieldDirectorServiceProxy,
        private businessDirectorsService: MutBusinessDirectorsServiceProxy,
        private businessTerminationService: MutBusinessTerminationServiceProxy,
        private documentService: MutDocumentServiceProxy,
        private mutationApplicationSearchService: MutationApplicationServiceProxy
    ) {
        super(injector, codeService, appService);
    }

    // getMutationTypes(sponsorshipTypeId: string, applicationType: ApplicationType): Observable<ClaStatusWizardStepDto[]> {
    //     return this.claStatusWizardStepServiceProxy.getAllBySponsorshipTypeIdAndApplicationType(sponsorshipTypeId, applicationType);
    //   }
    // getApplicationTypes(allOnTop: boolean) {
    //     return this.codeService.getEnumForDropdown('ApplicationType', ApplicationType, allOnTop)
    //         .filter(x => x.value === null || this.peopleApplicationTypes.includes(x.value));
    // }

    buildPendenceDto() {
        let dto = new CreatePendenceDto();
        dto.description = this.createMutPendenceDescription();
        dto.statusId = AppConsts.codes.pendenceStatus.oepn;
        dto.creationDate = DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
        dto.fieldId = AppConsts.codes.pendenceField.mutation;
        dto.typeId = AppConsts.codes.pendenceType.internal;
        dto.sendReminderEmailToResponsible = false;
        dto.reminderMailDistributionListIds = [];
        dto.links = [
            new PendenceLinkDto({
                discriminator: AppConsts.pendenceDiscriminators.application,
                entityId: this.application.id,
            }),
        ];
        return dto;
    }

    private createMutPendenceDescription() {
        let description = '';
        const separator = ' ';
        if (this.isApplicantPerson()) {
            description += this.mut.applicantPerson.lastName;
            description += separator + this.mut.applicantPerson.firstName;
            description += separator + this.mut.applicantPerson.gln;
        } else if (this.isApplicantBusiness()) {
            description += this.mut.applicantBusiness.business.gln;
            description += separator + this.mut.applicantBusiness.business.businessName;
            description += separator + this.mut.applicantBusiness.business.address.city;
        } else if (this.isApplicantSponsorship()) {
            description += this.mut.applicantSponsorship.uid;
            description += separator + this.mut.applicantSponsorship.name;
            description += separator + this.mut.applicantSponsorship.city;
        }
        description += separator + this.l(this.mut.mutationTypeId);
        return description;
    }

    isApplicantPerson(): boolean {
        return this.mut.firstStep === MutStatus.ApplicantPerson;
    }

    isApplicantBusiness(): boolean {
        return this.mut.firstStep === MutStatus.ApplicantBusiness;
    }

    isApplicantSponsorship(): boolean {
        return this.mut.firstStep === MutStatus.ApplicantSponsorship;
    }

    getMutationApplications(filter: string, sorting: string, maxResultCount: number, skipCount: number) {
        return this.mutationApplicationSearchService.getMutationApplications(
            filter,
            sorting,
            maxResultCount,
            skipCount
        );
    }

    get name(): string {
        let applicationName = '';
        if (this.mut.applicantPerson?.firstName && this.mut.applicantPerson?.lastName) {
            applicationName = this.mut.applicantPerson.firstName + ' ' + this.mut.applicantPerson.lastName;
        }
        if (this.mut.applicantSponsorship?.name) {
            applicationName = this.mut.applicantSponsorship.name;
        }
        if (this.mut.applicantBusiness?.business?.businessName) {
            applicationName = this.mut.applicantBusiness.business.businessName;
        }
        return applicationName;
    }



    getMutationApplicationTypes(allOnTop: boolean) {
        return this.codeService.getEnumForDropdown('ApplicationType', ApplicationType, allOnTop)
            .filter(x => x.value === null || this.mutApplicationTypes.includes(x.value));
    }


    getExternalSystemByProfessionId(professionId: string): Observable<ExternalSystemEnum> {
        return this.liaService.getExternalRegisterByProfessionId(professionId);
    }

    getActiveProfessions(): Observable<string[]> {
        return this.liaService.getActiveProfessions();
    }

    createMut(input: CreateMutInput): Observable<CreateMutOutput> {
        return this.mutService.createMut(input).pipe(
            tap((output: CreateMutOutput) => {
                this.mut = null;
            })
        );
    }

    deleteMut(): Observable<void> {
        return this.appService.deleteApplication(this.mut.application.caseId);
    }

    acceptMut(): Observable<void> {
        return this.mutService.acceptApplication(this.mut.application.caseId);
    }

    getMut(caseId: string): Observable<GetMutOutput> {
        this.mut = null;
        return this.getApplication(caseId).pipe(
            switchMap(() =>
                this.mutService.getMut(caseId).pipe(
                    tap((output: GetMutOutput) => {
                        this.mut = output.mut;
                    })
                )
            )
        );
    }

    getMyMuts(): Observable<MutDto[]> {
        return this.mutService.getMyMuts();
    }

    getMutsForPerson(gln: string): Observable<MutDto[]> {
        return this.mutService.getMutsForPerson(gln);
    }

    isInMutStatus(expectedStatus: MutStatus): boolean {
        return this.mut.statusId === expectedStatus;
    }

    getEvaluationComments(): Observable<string> {
        return this.mutService.getEvaluationComments(this._caseId);
    }

    releaseMut(comment: string): Observable<void> {
        return this.mutService.releaseMut(this.mut.application.caseId, comment);
    }

    getNextStatus(status: MutStatus): Observable<MutStatus> {
        let isAuthority = this.permission.isGranted('Pages.Authority.Applications');
        return this.mutService.getNextStatus(this._caseId, status).pipe(
            map((status) => {
                // Authority goes to 'finish review' and not to 'release'
                if (isAuthority && status === MutStatus.Release) {
                    return MutStatus.FinishReview;
                }
                return status;
            })
        );
    }

    getStepUrl(nextStep: MutStatus): string {
        return this.getNextStep(this.getUrlForStep(nextStep));
    }

    get firstStep(): MutStatus {
        let firstStep = this.mut.firstStep;
        return firstStep;
    }

    getFiles(step: MutStatus, hasCorporateLicenceInAnotherCantonId: string): Observable<GeneralDocumentGroupDto[]> {
        // const input = new GetMutDocumentsInput({ caseId: this._caseId, step: step, hasCorporateLicenceInAnotherCantonId: hasCorporateLicenceInAnotherCantonId, hasProfessionalLicenceId: undefined, hasAppliedForProfessionalLicenceId: undefined});
        const input = new GetMutDocumentsInput({ caseId: this._caseId, step: step });
        return this.documentService.getDocuments(input).pipe(map((output: MutDocumentsOutput) => output.documents));
    }

    getFile(step: MutStatus, id: number): Observable<GeneralFileDto> {
        const input = new GetMutFileInput();
        input.caseId = this._caseId;
        input.step = step;
        input.id = id;
        return this.documentService.getFile(input);
    }

    uploadFiles(input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadFiles(input);
    }

    deleteFile(step: MutStatus, id: number): Observable<void> {
        return this.documentService.deleteFile(this._caseId, step, id);
    }

    get caseId(): string {
        return this.mut.application.caseId;
    }

    //get medRegId(): number {
    //    return this.mut.person.medRegId;
    //}

    //get hasMedRegId(): boolean {
    //    return this.mut.person.medRegId != null;
    //}

    //get psyRegId(): number {
    //    return this.mut.person.psyRegId;
    //}

    //get hasPsyRegId(): boolean {
    //    return this.mut.person.psyRegId != null;
    //}

    //get naregId(): number {
    //    return this.mut.person.naregId;
    //}

    //get hasNaregId(): boolean {
    //    return this.mut.person.naregId != null;
    //}

    //get healthRegisterPersonId(): number {

    //    switch (this.healthRegisterSystem) {
    //        case ExternalSystemEnum.MedReg:
    //            return this.mut.person.medRegId;
    //        case ExternalSystemEnum.PsyReg:
    //            return this.mut.person.psyRegId;
    //        case ExternalSystemEnum.Nareg:
    //            return this.mut.person.naregId;
    //        default:
    //            return null;
    //    }
    //}

    get healthRegisterSystemId(): ExternalSystemEnum {
        return this.healthRegisterSystem;
    }

    setMutPersonStepToReview() {
        this.setStepToReview(MutStatus.Person);
    }

    getPerson(): Observable<MutPersonDto> {
        return this.personService.get(this.caseId);
    }

    createOrUpdatePerson(person: MutPersonDto): Observable<any> {
        this.setStepToReview(MutStatus.Person);
        return this.personService.createOrUpdate(this._caseId, person).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getApplicantContact(): Observable<MutApplicantContactDto> {
        return this.applicantContactService.get(this.caseId);
    }

    createOrUpdateApplicantContact(applicantContact: MutApplicantContactDto): Observable<any> {
        this.setStepToReview(MutStatus.ApplicantContact);
        return this.applicantContactService.createOrUpdate(this._caseId, applicantContact).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getApplicantPerson(): Observable<MutApplicantPersonDto> {
        return this.applicantPersonService.get(this.caseId);
    }

    createOrUpdateApplicantPerson(applicantPerson: MutApplicantPersonDto): Observable<any> {
        this.setStepToReview(MutStatus.ApplicantPerson);
        return this.applicantPersonService.createOrUpdate(this._caseId, applicantPerson).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getApplicantSponsorship(): Observable<MutApplicantSponsorshipDto> {
        return this.applicantSponsorshipService.get(this.caseId);
    }

    createOrUpdateApplicantSponsorship(applicantSponsorship: MutApplicantSponsorshipDto): Observable<any> {
        this.setStepToReview(MutStatus.ApplicantSponsorship);
        return this.applicantSponsorshipService.createOrUpdate(this._caseId, applicantSponsorship).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getApplicantBusiness(): Observable<MutApplicantBusinessDto> {
        return this.applicantBusinessService.get(this.caseId);
    }

    createOrUpdateApplicantBusiness(applicantBusinessInput: CreateUpdateMutApplicantBusinessInput): Observable<any> {
        this.setStepToReview(MutStatus.ApplicantBusiness);
        applicantBusinessInput.caseId = this._caseId;
        return this.applicantBusinessService.createOrUpdate(applicantBusinessInput).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getApplicantAdditionalComments(): Observable<MutApplicantAdditionalCommentsDto> {
        return this.applicantAdditionalCommentsService.get(this.caseId);
    }

    createOrUpdateAdditionalComments(applicationData: MutApplicantAdditionalCommentsDto): Observable<any> {
        this.setStepToReview(MutStatus.ApplicantAdditionalComments);
        return this.applicantAdditionalCommentsService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getAdditionalAcademicTitle(): Observable<MutAdditionalAcademicTitleDto> {
        return this.additionalAcademticTitleService.get(this.caseId);
    }

    createOrUpdateAdditionalAcademicTitle(applicationData: MutAdditionalAcademicTitleDto): Observable<any> {
        this.setStepToReview(MutStatus.AdditionalAcademicTitle);
        return this.additionalAcademticTitleService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getPostgraduateTitlePrivateLaw(): Observable<MutPostgraduateTitlePrivateLawDto> {
        return this.postgraduateTitlePrivateLawService.get(this.caseId);
    }

    createOrUpdatePostgraduateTitlePrivateLaw(applicationData: MutPostgraduateTitlePrivateLawDto): Observable<any> {
        this.setStepToReview(MutStatus.PostgraduateTitlePrivateLaw);
        return this.postgraduateTitlePrivateLawService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getAddress(): Observable<MutAddressDto> {
        return this.addressService.get(this.caseId);
    }

    createOrUpdateAddress(applicationData: MutAddressDto): Observable<any> {
        this.setStepToReview(MutStatus.Address);
        return this.addressService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getWorkPlace(): Observable<MutWorkPlaceDto> {
        return this.workPlaceService.get(this.caseId);
    }

    createOrUpdateWorkPlace(applicationData: MutWorkPlaceDto): Observable<any> {
        this.setStepToReview(MutStatus.WorkPlace);
        return this.workPlaceService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getEmployment(): Observable<MutEmploymentDto> {
        return this.employmentService.get(this.caseId);
    }

    createOrUpdateEmployment(applicationData: MutEmploymentDto): Observable<any> {
        this.setStepToReview(MutStatus.Employment);
        return this.employmentService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getEmploymentTermination(): Observable<MutEmploymentTerminationDto> {
        return this.employmentTerminationService.get(this.caseId);
    }

    createOrUpdateEmploymentTermination(applicationData: MutEmploymentTerminationDto): Observable<any> {
        this.setStepToReview(MutStatus.EmploymentTermination);
        return this.employmentTerminationService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getOther(): Observable<MutOtherDto> {
        return this.otherService.get(this.caseId);
    }

    createOrUpdateOther(applicationData: MutOtherDto): Observable<any> {
        this.setStepToReview(MutStatus.Other);
        return this.otherService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getSponsorship(): Observable<MutSponsorshipDto> {
        return this.sponsorshipService.get(this.caseId);
    }

    createOrUpdateSponsorship(applicationData: MutSponsorshipDto): Observable<any> {
        this.setStepToReview(MutStatus.Sponsorship);
        return this.sponsorshipService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getTerminationOfCla(): Observable<MutTerminationOfClaDto> {
        return this.terminationOfClaService.get(this.caseId);
    }

    createOrUpdateTerminationOfCla(applicationData: MutTerminationOfClaDto): Observable<any> {
        this.setStepToReview(MutStatus.TerminationOfCla);
        return this.terminationOfClaService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getTransferOfCla(): Observable<MutTransferOfClaDto> {
        return this.transferOfClaService.get(this.caseId);
    }

    createOrUpdateTransferOfCla(applicationData: MutTransferOfClaDto): Observable<any> {
        this.setStepToReview(MutStatus.TransferOfCla);
        return this.transferOfClaService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getBusiness(): Observable<MutBusinessDto> {
        return this.businessService.get(this.caseId);
    }

    createOrUpdateBusiness(applicationData: MutBusinessDto): Observable<any> {
        this.setStepToReview(MutStatus.Business);
        return this.businessService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getRangeOfServices(): Observable<MutRangeOfServicesDto> {
        return this.rangeOfServicesService.get(this.caseId);
    }

    createOrUpdateRangeOfServices(applicationData: MutRangeOfServicesDto): Observable<any> {
        this.setStepToReview(MutStatus.RangeOfServices);
        return this.rangeOfServicesService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getDrugProduction(): Observable<MutDrugProductionDto> {
        return this.drugProductionService.get(this.caseId);
    }

    createOrUpdateDrugProduction(applicationData: MutDrugProductionDto): Observable<any> {
        this.setStepToReview(MutStatus.DrugProduction);
        return this.drugProductionService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getSponsorshipDirectors(): Observable<GetMutSponsorshipDirectorsOutput> {
        return this.sponsorshipDirectorService.get(this.caseId);
    }

    createOrUpdateSponsorshipDirector(applicationData: CreateUpdateMutSponsorshipDirectorsInput): Observable<any> {
        this.setStepToReview(MutStatus.SponsorshipDirector);
        return this.sponsorshipDirectorService.createOrUpdate(applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    createPlaceholderSponsorshipDirector(applicationData: CreateUpdateMutSponsorshipDirectorsInput): Observable<number> {
        return this.sponsorshipDirectorService.createPlaceholder(applicationData);
    }

    getSponsorshipDirectorFiles(directorId: number, caseId = this._caseId): Observable<GeneralDocumentGroupDto[]> {
        const input = new GetMutSubItemDocumentsInput();
        input.caseId = caseId;
        input.step = MutStatus.SponsorshipDirector;
        input.subItemId = directorId;
        return this.documentService.getSponsorshipDirectorFiles(input).pipe(map((output: MutDocumentsOutput) => output.documents));
    }

    uploadSponsorshipDirectorFiles(directorId: number, input: ApplicationUploadFilesInput): Observable<GeneralFileInfoDto[]> {
        return this.documentService.uploadSponsorshipDirectorFiles(directorId, input);
    }

    getMedicalFieldDirector(): Observable<MutMedicalFieldDirectorDto> {
        return this.medicalFieldDirectorService.get(this.caseId);
    }

    createOrUpdateMedicalFieldDirector(applicationData: MutMedicalFieldDirectorDto): Observable<any> {
        this.setStepToReview(MutStatus.MedicalFieldDirector);
        return this.medicalFieldDirectorService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getBusinessTermination(): Observable<MutBusinessTerminationDto> {
        return this.businessTerminationService.get(this.caseId);
    }

    createOrUpdateBusinessTermination(applicationData: MutBusinessTerminationDto): Observable<any> {
        this.setStepToReview(MutStatus.BusinessTermination);
        return this.businessTerminationService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getBusinessChange(): Observable<MutBusinessChangeDto> {
        return this.businessChangeService.get(this.caseId);
    }

    createOrUpdateBusinessChange(applicationData: MutBusinessChangeDto): Observable<any> {
        this.setStepToReview(MutStatus.BusinessChange);
        return this.businessChangeService.createOrUpdate(this._caseId, applicationData).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    getBusinessDirectors(): Observable<GetMutBusinessDirectorsOutput> {
        return this.businessDirectorsService.get(this.caseId);
    }

    createOrUpdateBusinessDirectors(applicationData: MutBusinessDirectorDto[]): Observable<any> {
        this.setStepToReview(MutStatus.BusinessDirectors);
        let input = new CreateUpdateMutBusinessDirectorsInput();
        input.caseId = this.caseId;
        input.businessDirectors = applicationData;
        return this.businessDirectorsService.createOrUpdate(input).pipe(
            tap((newStatusId: MutStatus) => {
                this.mut.statusId = newStatusId;
            })
        );
    }

    checkDocuments(): Observable<MutStatus[]> {
        return this.mutService.validateThatAllMandatoryDocumentsHaveBeenUploaded(this.caseId);
    }

    getUrlForStep(step: number): string {
        switch (step) {
            case MutStatus.ApplicantPerson:
                return 'applicant-person';
            case MutStatus.ApplicantSponsorship:
                return 'applicant-sponsorship';
            case MutStatus.ApplicantBusiness:
                return 'applicant-business';

            case MutStatus.Person:
                return 'person';
            case MutStatus.Address:
                return 'address';
            case MutStatus.Business:
                return 'business';
            case MutStatus.BusinessChange:
                return 'business-change';
            case MutStatus.BusinessDirectors:
                return 'business-directors';
            case MutStatus.AdditionalAcademicTitle:
                return 'additional-academic-title';
            case MutStatus.BusinessTermination:
                return 'business-termination';
            case MutStatus.DrugProduction:
                return 'drug-production';
            case MutStatus.Employment:
                return 'employment';
            case MutStatus.EmploymentTermination:
                return 'employment-termination';
            case MutStatus.MedicalFieldDirector:
                return 'medical-field-director';
            case MutStatus.Other:
                return 'other';
            case MutStatus.PostgraduateTitlePrivateLaw:
                return 'postgraduate-title-private-law';
            case MutStatus.RangeOfServices:
                return 'range-of-services';
            case MutStatus.Sponsorship:
                return 'sponsorship';
            case MutStatus.SponsorshipDirector:
                return 'sponsorship-director';
            case MutStatus.TerminationOfCla:
                return 'termination-of-cla';
            case MutStatus.TransferOfCla:
                return 'transfer-of-cla';
            case MutStatus.WorkPlace:
                return 'work-place';
            case MutStatus.ApplicantAdditionalComments:
                return 'applicant-additional-comments';
            case MutStatus.ApplicantContact:
                return 'applicant-contact';

            case MutStatus.Release:
                return 'release';
            case MutStatus.FinishReview:
                return 'finish-review';

            default:
                console.error('Invalid status for getUrlForStep');
                return null;
        }
    }
}
