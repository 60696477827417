import { Injectable } from '@angular/core';
import { ContactDto, CreateOrUpdatePersonDto, CreatePersonAndLinkToEmploymentInput, CreatePersonFromEmploymentInfoDto, CreatePersonFromHealthRegisterInfoDto, GetPersonDossierSearchOutput, GetPersonDto, InvoiceAddressDto, PersonDossierServiceProxy, PersonDossierSummaryDto, PersonLicenceDto, PersonSearchDto, ProfessionalLicenceDto } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { ReplaySubject, tap, map, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

export interface IPersonDataCache {
    firstName: string | undefined;
    lastName: string | undefined;
    birthName: string | undefined;
    genderId: string | undefined;
    dateOfBirth: DateTime | undefined;
    dateOfDeath: DateTime | undefined;
    nationality1Id: string | undefined;
    nationality2Id: string | undefined;
    placeOfOrigin: string | undefined;
    gln: string | undefined;
    ahv: string | undefined;
    uid: string | undefined;
}

@Injectable({
    providedIn: 'root',
})
export class PersonDossierService {
    id: number;
    personDataSubject = new ReplaySubject<IPersonDataCache>();
    dataChangedSubject = new Subject<void>();

    constructor(
        private personDossierService: PersonDossierServiceProxy
    ) {}

    public SetId(id: number) {
        this.id = id;
        this.dataChangedSubject.next();
    }

    public GetId() {
        return this.id;
    }

    public DataChangedObserver(): Observable<void> {
        return this.dataChangedSubject.asObservable();
    }

    public GetUserId(): Observable<number> {
        return this.GetPersonDossierSummary().pipe(map(x => x.userId));
    }

    CreatePerson(personDto: CreateOrUpdatePersonDto): Observable<GetPersonDto> {
        return this.personDossierService.createPerson(personDto);
    }

    CreatePersonAndContactFromEmploymentInfo(personAndContact: CreatePersonFromEmploymentInfoDto): Observable<GetPersonDto> {
        return this.personDossierService.createPersonAndContactFromEmploymentInfo(personAndContact);
    }

    CreatePersonFromHealthRegisterInfo(personDto: CreatePersonFromHealthRegisterInfoDto): Observable<GetPersonDto> {
        return this.personDossierService.createPersonFromHealthRegisterInfo(personDto);
    }

    CreatePersonAndLinkToDeployment(input: CreatePersonAndLinkToEmploymentInput): Observable<GetPersonDto> {
        return this.personDossierService.createPersonAndLinkToEmployment(input);
    }

    UpdatePerson(personDto: CreateOrUpdatePersonDto): Observable<GetPersonDto> {
        return this.personDossierService.updatePerson(this.id, personDto);
    }

    public GetPersonDataSubject(): Observable<IPersonDataCache> {
        return this.personDataSubject;
    }

    public Search(input: PersonSearchDto): Observable<GetPersonDossierSearchOutput> {
        return this.personDossierService.search(input);
    }

    public GetPersonDossiers(filter: string, sorting: string, maxResultCount: number, skipCount: number): Observable<GetPersonDossierSearchOutput> {
        return this.personDossierService.getPersonDossiers(filter, sorting, maxResultCount, skipCount);
    }

    public GetPersonDossierSummary(): Observable<PersonDossierSummaryDto> {
        return this.personDossierService
            .getPersonSummary(this.id)
            .pipe(tap((x: PersonDossierSummaryDto) => {
                this.personDataSubject.next(x);
            }));
    }

    public GetPersonCoreData(): Observable<GetPersonDto> {
        return this.personDossierService.getPerson(this.id);
    }

    public UpdatePersonCoreData(input: CreateOrUpdatePersonDto) {
        return this.personDossierService.updatePerson(this.id, input)
            .pipe(tap(() => this.personDataSubject.next(input)));
    }

    public GetPersonContact() {
        return this.personDossierService.getContact(this.id);
    }

    public UpdateContact(input: ContactDto) {
        return this.personDossierService.putContact(this.id, input);
    }

    public GetPersonInvoiceAddress(): Observable<InvoiceAddressDto> {
        return this.personDossierService.getInvoiceAddress(this.id);
    }

    public getPersonLicences(personId: number): Observable<PersonLicenceDto[]> {
        return this.personDossierService.getPersonLicences(personId);
    }

    public UpdateInvoiceAddress(input: InvoiceAddressDto) {
        return this.personDossierService.putInvoiceAddress(this.id, input);
    }
}
